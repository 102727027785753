import {
  Accessor,
  ErrorBoundary,
  Match,
  Setter,
  Show,
  Switch,
  createEffect,
  createSignal,
  onMount,
} from "solid-js";
import { JSX } from "solid-js/jsx-runtime";
import {
  generatePartnerOTP,
  generateProcurementOtp,
  verifyPartnerCode,
  verifyPartnerOTP,
} from "~/server/apis/client_apis";
import { EditIcon } from "~/assets/svg_icons/edit_icon";
import { HubbleError } from "../error";
import { setCookie, getCookie } from "~/utils/client_cookie";
import { Cookie, Mode } from "~/types";
import { ThreeDotLoader } from "~/widgets/button";
import { useIsRouting, useNavigate } from "@solidjs/router";
import { APIError } from "~/utils/fetch";
import { RNREvent, rnrEventManager } from "~/data/events";
import { captureErrorInSentry } from "~/utils/third_party/sentry";
import { setIsFreshLogin } from "~/components/brand_l2/header";
import { AuthMethod } from "~/server/types/client";
import { getRequestEvent } from "solid-js/web";

type ErrorState = {
  phoneNumber?: string;
  email?: string;
  otp?: string;
  emailOrPhoneNumber?: string;
};

const LoginForm = ({
  clientSecret,
  clientId,
  authMethod,
  mode,
}: {
  clientSecret: () => string;
  clientId: () => string;
  authMethod: () => AuthMethod;
  mode: () => Mode;
}) => {
  const navigate = useNavigate();
  const [emailOrPhoneNumber, setEmailOrPhoneNumber] = createSignal("");
  const [otp, setOTP] = createSignal<string | null>(null);
  const [otpToken, setOTPToken] = createSignal<string | null>(null);
  const [errorState, setErrorState] = createSignal<ErrorState>({});
  const [loading, setLoading] = createSignal({
    generateOTP: false,
    verifyOTP: false,
    verifyCode: false,
  });
  const isRouting = useIsRouting();
  const [resetCountdown, setResetCountdown] = createSignal(30);
  let timer: NodeJS.Timeout;
  const requestEvent = getRequestEvent();

  let inputRef: HTMLInputElement | undefined;

  function identifyInput(input: string) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;

    if (emailRegex.test(input)) {
      return "email";
    }
    if (phoneRegex.test(input)) {
      return "phoneNumber";
    }

    return null;
  }

  createEffect(() => {
    if (otpToken() !== null && (otp() ?? "").length === 6) {
      verifyOTP();
    }
  });

  onMount(() => {
    inputRef?.focus();
  });

  const redirectToWeb = (url: string) => {
    window.open(url, "_blank");
  };

  const verifyCode = async () => {
    setLoading((prevLoadingState) => ({
      ...prevLoadingState,
      verifyCode: true,
    }));

    try {
      const verifyCodeResponse = await verifyPartnerCode(
        {
          clientId: clientId(),
          clientSecret: clientSecret(),
        },
        {
          code: emailOrPhoneNumber(),
        },
        {
          isUnauthenticated: true,
        }
      );

      if (verifyCodeResponse.sessionId) {
        setCookie({
          key: Cookie.SessionId,
          value: verifyCodeResponse.sessionId,
          expiryInMinutes: 30,
        });
        setIsFreshLogin(true);
        navigate("/");
      } else {
        setErrorState({
          emailOrPhoneNumber:
            "An unexpected error occurred. Please try again later.",
        });
      }
    } catch (error: unknown) {
      if (error instanceof APIError) {
        setErrorState({ emailOrPhoneNumber: error.message });
      } else {
        setErrorState({
          emailOrPhoneNumber:
            "An unexpected error occurred. Please try again later.",
        });
      }
    } finally {
      setLoading({
        generateOTP: false,
        verifyOTP: false,
        verifyCode: false,
      });
    }
  };

  const verifyOTP = async () => {
    setLoading((prevLoadingState) => ({
      ...prevLoadingState,
      verifyOTP: true,
    }));
    const inputType = identifyInput((emailOrPhoneNumber() ?? "").trim());
    try {
      const verifyOTPResponse = await verifyPartnerOTP(
        {
          clientId: getCookie(Cookie.ClientId) ?? clientId(),
          clientSecret: getCookie(Cookie.ClientSecret) ?? clientSecret(),
        },
        {
          [inputType!]: emailOrPhoneNumber(),
          otp: otp() ?? "",
          otpToken: otpToken() ?? "",
        },
        {
          isUnauthenticated: true,
          throwAuthError: true,
        }
      );

      if (verifyOTPResponse.sessionId) {
        rnrEventManager.sendEvent(RNREvent.VERIFY_OTP_SUCCESS);
        setCookie({
          key: Cookie.SessionId,
          value: verifyOTPResponse.sessionId,
          expiryInMinutes: 30,
        });
        setIsFreshLogin(true);
        if (mode() === "squid") {
          navigate("/catalogue");
        } else {
          navigate("/");
        }
      } else {
        setErrorState({
          otp: "An unexpected error occurred. Please try again later.",
        });
        resetTimer();
      }
    } catch (error: unknown) {
      if (error instanceof APIError && error.code === 401) {
        setErrorState({ otp: "Invalid OTP" });
      } else {
        setErrorState({
          otp: "An unexpected error occurred. Please try again later.",
        });
      }
      rnrEventManager.sendEvent(RNREvent.VERIFY_OTP_FAILURE, {
        error: error,
      });
      resetTimer();
    } finally {
      setOTP("");
      setLoading({
        generateOTP: false,
        verifyOTP: false,
        verifyCode: false,
      });
    }
  };

  const handleOTPChange = (event: Event) => {
    const value = (event.currentTarget as HTMLInputElement).value;
    setErrorState({});
    setOTP(value);
  };

  const resendOtp = () => {
    setOTP(null);
    const inputType = identifyInput((emailOrPhoneNumber() ?? "").trim());
    if (inputType) {
      generateOTP(inputType);
    }
  };

  const resetTimer = () => {
    clearInterval(timer);
    setResetCountdown(0);
  };

  const startCountdown = () => {
    setResetCountdown(30);
    timer = setInterval(() => {
      setResetCountdown((prev) => {
        if (prev === 1) {
          clearInterval(timer);
          return prev - 1;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleSubmit: JSX.EventHandler<HTMLFormElement, Event> = (event) => {
    event.preventDefault();

    if (authMethod() === "CODE") {
      if (
        emailOrPhoneNumber().length >= 15 &&
        emailOrPhoneNumber().length <= 24
      ) {
        verifyCode();
        return;
      } else {
        setErrorState({
          emailOrPhoneNumber: "Please enter a valid code.",
        });
      }
    } else {
      if (otpToken() !== null && (otp() ?? "").length === 6) {
        verifyOTP();
        return;
      }

      const inputType = identifyInput((emailOrPhoneNumber() ?? "").trim());
      if (inputType) {
        generateOTP(inputType);
      } else {
        setErrorState({
          emailOrPhoneNumber:
            "Please enter a valid email or a 10-digit phone number.",
        });
      }
    }
  };

  const handleInput = (e: InputEvent, setValue: Setter<string>) => {
    const target = e.currentTarget as HTMLInputElement;
    setValue(target.value);
    setErrorState({});
  };

  const generateOTP = async (inputType: "email" | "phoneNumber") => {
    setLoading((prevLoadingState) => ({
      ...prevLoadingState,
      generateOTP: true,
    }));
    setErrorState({});
    try {
      if (mode() === "squid") {
        const response = await generateProcurementOtp({
          [inputType]: emailOrPhoneNumber(),
        });
        if (response) {
          setOTPToken(response.otpToken);

          startCountdown();
          setLoading((prevLoadingState) => ({
            ...prevLoadingState,
            generateOTP: false,
          }));
          rnrEventManager.sendEvent(RNREvent.GENERATE_OTP_SUCCESS);

          setCookie({
            key: Cookie.ClientId,
            value: response.clientId,
            expiryInDays: 1,
          });
          setCookie({
            key: Cookie.ClientSecret,
            value: response.clientId,
            expiryInDays: 1,
          });
        }
      } else {
        const response = await generatePartnerOTP(
          {
            clientId: clientId(),
            clientSecret: clientSecret(),
          },
          {
            [inputType]: emailOrPhoneNumber(),
          },
          {
            isUnauthenticated: true,
          }
        );

        if (response.otpToken) {
          setOTPToken(response.otpToken);
          startCountdown();
          setLoading((prevLoadingState) => ({
            ...prevLoadingState,
            generateOTP: false,
          }));
          rnrEventManager.sendEvent(RNREvent.GENERATE_OTP_SUCCESS);
        }
      }
    } catch (error) {
      if (error instanceof APIError) {
        setErrorState({
          emailOrPhoneNumber: error.message,
        });
        rnrEventManager.sendEvent(
          error.code === 429
            ? RNREvent.GENERATE_OTP_LIMIT_FAILURE
            : RNREvent.GENERATE_OTP_FAILURE,
          {
            error: error,
          }
        );
      } else {
        setErrorState({
          emailOrPhoneNumber: "Something went wrong. Please try again later.",
        });
      }
    } finally {
      setLoading({
        generateOTP: false,
        verifyOTP: false,
        verifyCode: false,
      });
    }
  };

  const getInputPlaceholder = (authMethod: AuthMethod): string => {
    return authMethod === "EMAIL"
      ? "Official email ID"
      : authMethod === "PHONE"
        ? "10 digit phone no."
        : authMethod === "CODE"
          ? ""
          : "Official email ID or 10 digit phone no.";
  };

  return (
    <ErrorBoundary
      fallback={(err) => {
        captureErrorInSentry(err);
        return <HubbleError errorMessage={err.message} />;
      }}
    >
      <form class="m-auto mx-5 lg:mx-[5%]" onSubmit={handleSubmit}>
        <Show when={otpToken() === null && otp() === null} fallback={<></>}>
          <div class="min-w-sm mb-4 flex flex-col gap-3">
            <label for="emailOrPhoneNumber" class="text-bold text-textDark">
              <Switch fallback={<>Email ID / phone no.</>}>
                <Match when={authMethod() === "EMAIL"}>Email ID</Match>
                <Match when={authMethod() === "PHONE"}>Phone number</Match>
                <Match when={authMethod() === "CODE"}>
                  <div class="text-center text-f12Bold uppercase text-textNormal">
                    Enter Code
                  </div>
                </Match>
              </Switch>
            </label>
            <input
              type="text"
              id="emailOrPhoneNumber"
              value={emailOrPhoneNumber() ?? ""}
              ref={inputRef}
              title={getInputPlaceholder(authMethod())}
              placeholder={getInputPlaceholder(authMethod())}
              onInput={(e) => handleInput(e, setEmailOrPhoneNumber)}
              required
              class={`text-sm block w-full rounded-xl border px-4 py-3 placeholder:text-medium focus:border-2 focus:border-baseDark focus:ring-baseDark disabled:pointer-events-none disabled:opacity-50 ${
                (errorState().email?.length ?? 0) > 0 ||
                (errorState().phoneNumber?.length ?? 0) > 0 ||
                (errorState().emailOrPhoneNumber?.length ?? 0) > 0
                  ? "border-errorDark"
                  : "border-gray-200"
              }`}
            />
            <Show
              when={
                (errorState().email?.length ?? 0) > 0 ||
                (errorState().phoneNumber?.length ?? 0) > 0 ||
                (errorState().emailOrPhoneNumber?.length ?? 0) > 0
              }
            >
              <p class="mt-1 text-f12 text-errorDark">
                {errorState().email ||
                  errorState().phoneNumber ||
                  errorState().emailOrPhoneNumber}
              </p>
            </Show>
          </div>
        </Show>

        <Show when={(otpToken() ?? "").length > 0} fallback={<></>}>
          <h2 class="text-bold text-textDark">Verify OTP</h2>
          <div class="flex items-center gap-1 text-medium text-textNormal">
            Sent to{" "}
            <div
              class="flex cursor-pointer gap-1"
              onClick={() => {
                setOTPToken(null);
                setOTP(null);
                setResetCountdown(30);
              }}
            >
              <span class="underline">{emailOrPhoneNumber()}</span>
              <EditIcon size={"18"} />
            </div>
          </div>
          <OtpInput
            otp={otp}
            setOTP={setOTP}
            handleOTPChange={handleOTPChange}
            isError={(errorState().otp?.length ?? 0) > 0}
            removeFocus={
              loading().generateOTP || loading().verifyOTP || isRouting()
            }
          />
          <Show when={errorState().otp?.length ?? 0 > 0}>
            <p class="mt-2 text-f12 text-errorDark">{errorState().otp}</p>
          </Show>
          <Show when={resetCountdown() > 0} fallback={<></>}>
            <p class="mt-3 text-medium text-textNormal">
              Resend OTP in {resetCountdown()}
            </p>
          </Show>
          <Show when={resetCountdown() === 0}>
            <div
              class="mt-3"
              onClick={() => {
                resendOtp();
                setResetCountdown(30);
              }}
            >
              <p class={"text-medium text-textNormal"}>
                {"Didn't get OTP? "}
                <span class={"cursor-pointer text-textDark underline"}>
                  Resend OTP
                </span>
              </p>
            </div>
          </Show>
        </Show>

        <Show
          when={
            otpToken() === null && otp() === null && authMethod() !== "CODE"
          }
        >
          <p class="text-medium text-textNormal">
            {"By continuing, I agree to the "}
            <span
              class="cursor-pointer text-textDark underline"
              onClick={() => {
                redirectToWeb(
                  "https://www.myhubble.money/terms-and-conditions"
                );
              }}
            >
              terms & conditions
            </span>
            {" and "}
            <span
              class="cursor-pointer text-textDark underline"
              onClick={() => {
                redirectToWeb("https://www.myhubble.money/privacy-policy");
              }}
            >
              privacy policy
            </span>
          </p>
        </Show>
        <button
          type="submit"
          class={`text-sm my-4 mb-0 flex h-12 w-full items-center justify-center rounded-full bg-basePrimaryDark py-3 text-baseTertiaryLight disabled:cursor-not-allowed disabled:bg-gray-200 disabled:py-4 disabled:text-baseSecondaryMedium`}
          disabled={
            loading().generateOTP ||
            loading().verifyOTP ||
            loading().verifyCode ||
            isRouting()
          }
        >
          {loading().generateOTP ||
          loading().verifyOTP ||
          loading().verifyCode ||
          isRouting() ? (
            <ThreeDotLoader color={"#000"} />
          ) : (
            <p class="text-f16Bold">
              <Switch fallback={"Continue"}>
                <Match when={authMethod() === "CODE"}>Redeem</Match>
              </Switch>
            </p>
          )}
        </button>
      </form>
    </ErrorBoundary>
  );
};

export default LoginForm;

export function OtpInput(props: {
  otp: Accessor<string | null>;
  setOTP: Setter<string | null>;
  handleOTPChange: (e: Event) => void;
  isError: boolean;
  removeFocus: boolean;
}) {
  const [hasFocus, setHasFocus] = createSignal<boolean>(false);

  let otpInput: HTMLInputElement | undefined;

  createEffect(async () => {
    otpInput?.focus();
  });

  createEffect(() => {
    if (props.removeFocus) {
      otpInput?.blur();
    } else {
      otpInput?.focus();
    }
  });

  const handleDivClick = () => {
    otpInput?.focus();
    setHasFocus(true);
  };

  const handlePaste = (event: ClipboardEvent) => {
    event.preventDefault();
    const pasteData = event.clipboardData?.getData("text");
    if (pasteData) {
      const sanitizedData = pasteData.replace(/\D/g, "").slice(0, 6);
      props.setOTP(sanitizedData);
      const inputEvent = new Event("input", { bubbles: true });
      Object.assign(inputEvent, {
        currentTarget: { value: sanitizedData },
      });
      props.handleOTPChange(inputEvent);
    }
  };

  createEffect(async () => {
    if ("OTPCredential" in window) {
      console.log("OTP autofill supported");
      const ac = new AbortController();

      setTimeout(() => {
        ac.abort("OTP autofill aborted after 30 seconds.");
      }, 30 * 1000);

      let options: CredentialRequestOptions = {
        otp: { transport: ["sms"] },
        signal: ac.signal,
      };

      try {
        const otp = await window.navigator["credentials"].get(options);
        if (otp && otp.code) {
          props.setOTP(otp.code);
          const inputEvent = new Event("input", { bubbles: true });
          Object.assign(inputEvent, {
            currentTarget: { value: otp.code },
          });
          props.handleOTPChange(inputEvent);
        }
      } catch (error) {
        ac.abort(`OTP autofill aborted due to error ${error}`);
        console.error(error);
      }
    }
  });

  return (
    <div class="relative mt-3 flex max-w-sm flex-col">
      <input
        ref={otpInput}
        class="h-0 w-full bg-transparent text-baseTertiaryLight caret-transparent "
        type="text"
        autocomplete="one-time-code"
        inputmode="numeric"
        value={props.otp() ?? ""}
        required
        maxLength={6}
        placeholder=""
        onInput={(event) => props.handleOTPChange(event)}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        onPaste={handlePaste}
      />
      <div class="z-20 flex w-full items-center justify-around space-x-2">
        {[...Array(6)].map((_, index) => (
          <div
            class={`flex h-12 w-16 cursor-text items-center justify-center rounded-[12px] border`}
            onClick={handleDivClick}
            classList={{
              "border-baseDark":
                index == props.otp()?.length && !props.removeFocus,
              "border-2": index == props.otp()?.length && !props.removeFocus,
              "border-baseSecondaryLight":
                index != props.otp()?.length && !props.removeFocus,
              "border-errorDark": props.isError,
            }}
          >
            {(props.otp() ?? "")[index] ? (
              (props.otp() ?? "")[index]
            ) : index == (props.otp() ?? "")?.length && hasFocus() ? (
              <div
                class={`h-3 w-0.5 animate-[blink_1s_infinite] bg-baseDark`}
              />
            ) : (
              <div class="h-1 w-1 rounded-full bg-baseSecondaryLight" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
